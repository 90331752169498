<template>
  <div id="Dashboard">
    <a-page-header
      title="运营数据管理"
      style="padding-left: 0; margin-bottom: 10px; border-bottom: 1px solid rgb(235, 237, 240)"
    />
    <a-spin :spinning="spinning">
      
    <a-tabs
      tab-position="left"
      :tabBarStyle="{ width: '220px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }"
      type="card"
      @change="tabsChange"
    >
      <a-tab-pane
        v-for="item in projectList"
        :key="item.id + ',' + item.project_name + ',' + item.child_type"
        :tab="item.project_name"
      >
        <a-card>
          <div class="title">资产数据</div>
          <div class="dataItem" v-for="(item, index) in form.propertyDataList" :key="index">
            <div>
              数据项&nbsp;&nbsp;
              <a-input :disabled="editType" size="small" placeholder="请输入数据项" v-model="item.dataKey" style="width: 150px"></a-input>
            </div>
            <div>
              数据值&nbsp;&nbsp;
              <a-input :disabled="editType" v-model="item.dateValue" placeholder="请输入数据值" size="small" style="width: 150px"></a-input>
            </div>
            <div>
              数据单位&nbsp;&nbsp;
              <a-input :disabled="editType" size="small" placeholder="请输入数据单位" style="width: 150px" v-model="item.dateUnit"></a-input>
              <a-icon
                v-if="!editType"
                @click="removeData(index)"
                style="margin-left: 10px; cursor: pointer"
                type="minus-circle"
              />
            </div>
          </div>
          <a-button :disabled="editType" style="margin-top: 10px" size="small" @click="addData()" type="primary">
            新增数据项</a-button
          >
          <a-divider />
          <div style="display: flex" v-if="isMaster == 0">
            <div>
              产业基金&nbsp;&nbsp;
              <a-input
                :disabled="editType"
                addon-after="支"
                size="small"
                v-model="form.propertyFundTotal"
                style="width: 200px"
                placeholder="请输入产业基金"
              ></a-input>
            </div>
            <div style="margin-left: 30px">
              <a-input
              placeholder="请输入产业基金"
                :disabled="editType"
                addon-after="亿元"
                size="small"
                v-model="form.propertyFundAmount"
                style="width: 200px"
              ></a-input>
            </div>
          </div>
          <div class="title title1" v-if="isMaster == 0">企业赋能</div>
          <div>
            <div style="margin-top: 10px" v-if="isMaster == 0" :ref="'editorContainer' + item.id"></div>
          </div>
          <div class="title title1">明星企业</div>
          <div class="titleTip">&nbsp;提示：图片最多上传20张，每张大小限制5M。</div>
          <a-upload
            :disabled="editType"
            style="margin-top: 4px"
            list-type="picture-card"
            :action="IMG_API + '/oss/files'"
            class="avatar-uploader"
            :remove="(file) => handleRemove(file, 'starEnterpriseImgList')"
            :file-list="imgArr.starEnterpriseImgList"
            @change="(info) => handleChange(info, 'starEnterpriseImgList')"
            @preview="handlePreview"
            :before-upload="beforeUpload"
            :showUploadList="{
              showRemoveIcon: !editType,
            }"
          >
            <div v-if="imgArr.starEnterpriseImgList.length < 20">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
          <div class="title title1" v-if="isMaster == 0">园区荣誉</div>

          <div class="titleTip" v-if="isMaster == 0">&nbsp;提示：图片最多上传20张，每张大小限制5M。</div>
          <a-upload
            v-if="isMaster == 0"
            :disabled="editType"
            style="margin-top: 4px"
            list-type="picture-card"
            :action="IMG_API + '/oss/files'"
            class="avatar-uploader"
            :file-list="imgArr.projectHonourImgList"
            :remove="(file) => handleRemove(file, 'projectHonourImgList')"
            @change="(info) => handleChange(info, 'projectHonourImgList')"
            @preview="handlePreview"
            :before-upload="beforeUpload"
            :showUploadList="{
              showRemoveIcon: !editType,
            }"
          >
            <div v-if="imgArr.projectHonourImgList.length < 20">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>

          <div v-if="isMaster == 1" class="title title1">明星企业展示图</div>

          <div class="titleTip" v-if="isMaster == 1">&nbsp;提示：图片最多上传1张，每张大小限制5M。</div>
          <a-upload
            v-if="isMaster == 1"
            :disabled="editType"
            style="margin-top: 4px"
            list-type="picture-card"
            :action="IMG_API + '/oss/files'"
            class="avatar-uploader"
            :remove="(file) => handleRemove(file, 'starEnterpriseShowImgList')"
            :file-list="imgArr.starEnterpriseShowImgList"
            @change="(info) => handleChange(info, 'starEnterpriseShowImgList')"
            @preview="handlePreview"
            :before-upload="beforeUpload"
            :showUploadList="{
              showRemoveIcon: !editType,
            }"
          >
            <div v-if="imgArr.starEnterpriseShowImgList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>

          <div class="title title1" v-if="isMaster == 1">优质商户</div>
          <div class="titleTip" v-if="isMaster == 1">&nbsp;提示：图片最多上传20张，每张大小限制5M。</div>
          <a-upload
            v-if="isMaster == 1"
            :disabled="editType"
            style="margin-top: 4px"
            list-type="picture-card"
            :remove="(file) => handleRemove(file, 'highQualityMerchantImgList')"
            :action="IMG_API + '/oss/files'"
            class="avatar-uploader"
            :file-list="imgArr.highQualityMerchantImgList"
            @change="(info) => handleChange(info, 'highQualityMerchantImgList')"
            @preview="handlePreview"
            :before-upload="beforeUpload"
            :showUploadList="{
              showRemoveIcon: !editType,
            }"
          >
            <div v-if="imgArr.highQualityMerchantImgList.length < 20">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>

          <div class="title title1" v-if="isMaster == 1">企业需求二维码</div>
          <div class="titleTip" v-if="isMaster == 1">&nbsp;提示：图片最多上传1张，每张大小限制5M。</div>
          <a-upload
            v-if="isMaster == 1"
            :disabled="editType"
            style="margin-top: 4px"
            list-type="picture-card"
            :action="IMG_API + '/oss/files'"
            class="avatar-uploader"
            :file-list="imgArr.enterpriseNeedsQrCodeImgList"
            :remove="(file) => handleRemove(file, 'enterpriseNeedsQrCodeImgList')"
            @change="(info) => handleChange(info, 'enterpriseNeedsQrCodeImgList')"
            @preview="handlePreview"
            :before-upload="beforeUpload"
            :showUploadList="{
              showRemoveIcon: !editType,
            }"
          >
            <div v-if="imgArr.enterpriseNeedsQrCodeImgList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>

          <div class="title title1" v-if="isMaster == 1">园区配套</div>
          <div class="titleTip" v-if="isMaster == 1">&nbsp;提示：图片最多上传20张，每张大小限制5M。</div>
          <a-upload
            v-if="isMaster == 1"
            :disabled="editType"
            style="margin-top: 4px"
            list-type="picture-card"
            :action="IMG_API + '/oss/files'"
            class="avatar-uploader"
            :file-list="imgArr.projectSupportingImgList"
            :remove="(file) => handleRemove(file, 'projectSupportingImgList')"
            @change="(info) => handleChange(info, 'projectSupportingImgList')"
            @preview="handlePreview"
            :before-upload="beforeUpload"
            :showUploadList="{
              showRemoveIcon: !editType,
            }"
          >
            <div v-if="imgArr.projectSupportingImgList.length < 20">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>

          <div class="flexCenter magin20">
            <a-button v-show="editType" type="primary" @click="checkEditBut">编辑</a-button>
            <a-button v-show="editType == false" type="primary" @click="submit">保存</a-button>
            <a-button v-show="editType == false" @click="checkEditBut('cancel')" type="redo" class="maginleft10">取消</a-button>
          </div>
        </a-card>
      </a-tab-pane>
    </a-tabs>
  </a-spin>

    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
  
<script>
import { IMG_API } from "@/config";
import WangEditor from "wangeditor";
import { useProjectList, manageSave, manageDetail } from "../../api/dataAnalysis/operationalApi";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  data() {
    return {
      spinning:false,
      isMaster: 2,
      editType: true,
      previewVisible: false,
      IMG_API: IMG_API,
      previewImage: "", //预览图片地址
      fileList: [],
      projectList: [],
      form: {
        propertyDataList: [
          {
            //数据项
            dataKey: "",
            dateValue: "",
            dateUnit: "",
          },
        ],
        projectId: "", //项目ID
        propertyFundTotal: "", //产业基金数量
        propertyFundAmount: "", //产业基金金额
        enterpriseEnergizeText: "", //富文本内容
        starEnterpriseImgList: [], //明星企业合集
        projectHonourImgList: [], //园区荣誉图片集合
        starEnterpriseShowImgList: [], //明星企业展示图集合
        highQualityMerchantImgList: [], //优质商户图片集合
        enterpriseNeedsQrCodeImgList: [], //企业需求二维码图片集合
        projectSupportingImgList: [], //园区配套图片集合
      },
      imgArr: {
        starEnterpriseImgList: [], //明星企业合集
        projectHonourImgList: [], //园区荣誉图片集合
        starEnterpriseShowImgList: [], //明星企业展示图集合
        highQualityMerchantImgList: [], //优质商户图片集合
        enterpriseNeedsQrCodeImgList: [], //企业需求二维码图片集合
        projectSupportingImgList: [], //园区配套图片集合
      },
      editor: null,
      editorTxt: "",
    };
  },
  components: {},

  mounted() {
    this.$nextTick(()=>{
      this.getProjectList();
    })
  },
  computed: {},
  methods: {
    initEditor(tabId) {
      if (this.editor) {
        this.editor.destroy();
        this.editor = null;
      }

      //初始化富文本
      if (!this.editor) {
        this.editor = new WangEditor(this.$refs[`editorContainer${tabId}`]);
        this.editor.config.onchange = (newHtml) => {
          this.form.enterpriseEnergizeText = newHtml;
        };
        this.editor.config.zIndex = 200;
        this.editor.config.showFullScreen = false;
        this.editor.config.menus = ["bold", "justify", "undo", "redo"];
        this.editor.create();
        if (this.editType) {
          this.editor.disable();
        } else {
          this.editor.enable();
        }
      }
      this.editor.txt.html(this.editorTxt);
    },
    async getProjectList() {
      //获取项目
      let res = await useProjectList();
      this.projectList = res.data;
      this.isMaster = res.data[0].child_type;
      this.form.projectId = res.data[0].id;
      setTimeout(() => {
        this.initEditor(this.form.projectId);
      }, 1000);
      this.init();
    },
   
    async init() {
      //详情
      let res = await manageDetail({ projectId: this.form.projectId });
      if (res.data != null) {
        this.form = res.data;
        this.editorTxt = res.data.enterpriseEnergizeText;
        if (this.isMaster == 0 && this.editor) {
          setTimeout(() => {
            this.editor.txt.html(res.data.enterpriseEnergizeText);
          }, 1000);
        }
        const validImgarr1 = res.data.starEnterpriseImgList || [];
        const validImgarr2 = res.data.projectHonourImgList || [];
        const validImgarr3 = res.data.starEnterpriseShowImgList || [];
        const validImgarr4 = res.data.highQualityMerchantImgList || [];
        const validImgarr5 = res.data.enterpriseNeedsQrCodeImgList || [];
        const validImgarr6 = res.data.enterpriseNeedsprojectSupportingImgListQrCodeImgList || [];
        const arrImgUrl = [
          ...validImgarr1,
          ...validImgarr2,
          ...validImgarr3,
          ...validImgarr4,
          ...validImgarr5,
          ...validImgarr6,
        ]
        let imagePromises = arrImgUrl.map((imgSrc) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = imgSrc;
            this.spinning = true
            img.onload = () => resolve(imgSrc); // 图片加载成功
            img.onerror = () => reject(new Error(`图片加载失败: ${imgSrc}`)); // 图片加载失败
          });
        });
        
        let a = []
        Promise.all(imagePromises).then((images) => {
          a = images;
          if (res.data.starEnterpriseImgList != null)
          this.imgArr.starEnterpriseImgList = this.transformImageList(res.data.starEnterpriseImgList); // 明星企业合集
        if (res.data.projectHonourImgList != null)
          this.imgArr.projectHonourImgList = this.transformImageList(res.data.projectHonourImgList); // 园区荣誉图片集合
        if (res.data.starEnterpriseShowImgList != null)
          this.imgArr.starEnterpriseShowImgList = this.transformImageList(res.data.starEnterpriseShowImgList); // 明星企业展示图集合
        if (res.data.highQualityMerchantImgList != null)
          this.imgArr.highQualityMerchantImgList = this.transformImageList(res.data.highQualityMerchantImgList); // 优质商户图片集合
        if (res.data.enterpriseNeedsQrCodeImgList != null)
          this.imgArr.enterpriseNeedsQrCodeImgList = this.transformImageList(res.data.enterpriseNeedsQrCodeImgList); // 企业需求二维码图片集合
        if (res.data.projectSupportingImgList != null)
          this.imgArr.projectSupportingImgList = this.transformImageList(res.data.projectSupportingImgList); // 园区配套图片集合
          this.spinning = false
          
        }).catch((error) => {
          this.spinning = false
          console.error(error); // 处理加载失败的情况
        });


        
      } else {
        (this.form.propertyDataList = [
          {
            dataKey: "",
            dateValue: "",
            dateUnit: "",
          },
        ]),
          (this.form.propertyFundTotal = ""), // 产业基金数量
          (this.form.propertyFundAmount = ""), // 产业基金金额
          (this.form.enterpriseEnergizeText = ""), // 富文本内容
          (this.form.starEnterpriseImgList = []), // 明星企业合集
          (this.form.projectHonourImgList = []), // 园区荣誉图片集合
          (this.form.starEnterpriseShowImgList = []), // 明星企业展示图集合
          (this.form.highQualityMerchantImgList = []), // 优质商户图片集合
          (this.form.enterpriseNeedsQrCodeImgList = []), // 企业需求二维码图片集合
          (this.form.projectSupportingImgList = []), // 园区配套图片集合
          (this.imgArr = {
            starEnterpriseImgList: [], // 明星企业合集
            projectHonourImgList: [], // 园区荣誉图片集合
            starEnterpriseShowImgList: [], // 明星企业展示图集合
            highQualityMerchantImgList: [], // 优质商户图片集合
            enterpriseNeedsQrCodeImgList: [], // 企业需求二维码图片集合
            projectSupportingImgList: [], // 园区配套图片集合
          });
      }
    },
    // 将数组转换为指定格式的函数
    transformImageList(imageList) {
      return imageList.map((url, index) => {
        const name = url.split("/").pop(); // 提取图片名
        return {
          uid: index + 1,
          name: name,
          url: url,
          response: {
            redirect_uri: url,
          },
        };
      });
    },
    tabsChange(key) {
      this.reForm()
      this.reImgArr()
      //切换项目
      this.editorTxt = "";
      this.editType = true;
      const keyArr = key.split(",");
      this.isMaster = keyArr[2];
      this.form.projectId = keyArr[0];
      setTimeout(() => {
        this.initEditor(this.form.projectId);
      }, 1000);
      this.init();
    },
    addData() {
      //增加一条数据项
      this.form.propertyDataList.push({ dataItem: "", dataValue: "", dataUnit: "" });
    },
    removeData(index) {
      //删除一条数据项
      if (this.form.propertyDataList.length > 1) {
        this.form.propertyDataList.splice(index, 1);
      } else {
        this.$message.error("最少需要一条数据");
      }
    },
    handleRemove(file, listType) {
      let f = file.response.redirect_uri
      // this.form[listType] = this.form[listType].filter((uri) => uri != f);
      this.imgArr[listType] = this.imgArr[listType].filter((item) => item.response.redirect_uri != f);
    },
    handleChange(data, name) {
      if (data.fileList.length == 0) {
        this.form[name] = [];
        return;
      }
      const isJpgOrPng =
        data.file.type === "image/jpeg" || data.file.type === "image/png" || data.file.type === "image/jpg";
      const isLt5M = data.file.size / 1024 / 1024 < 5;
      // //如果通过校验 再给图片赋值
      if (isJpgOrPng && isLt5M) {
        // if (data.file.status == "done") {
        //   this.form[name] = data.fileList.map((item) => item.response.redirect_uri);
        // }
        this.imgArr[name] = [...data.fileList];
      }
    },
    //查看图片
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    //上传图片前校验
    beforeUpload(file) {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJpgOrPng && !isLt5M) {
        return this.$message.error("请上传jpeg/png/jpg格式图片且图片大小不可超过5M!");
      } else {
        if (!isJpgOrPng) {
          this.$message.error("请上传jpeg/png/jpg格式图片");
        }
        if (!isLt5M) {
          this.$message.error("图片大小不可超过5MB!");
        }
        return isJpgOrPng && isLt5M;
      }
    },
    checkEditBut(data) {
      if(data){
        this.init()
      }
      //编辑
      this.editType = !this.editType;
      if (this.isMaster == 0) {
        if (this.editType) {
          this.editor.disable();
        } else {
          this.editor.enable();
        }
      }
    },

    submit() {
      this.populateFormWithImages()
      //保存
      manageSave(this.form).then((res) => {
        if (res.code == "200") {
          this.$message.success("保存成功");
          if (this.isMaster == 0) this.editor.disable();
          this.editType = !this.editType;
        } else {
          if (this.isMaster == 0) this.editor.enable();
          this.$message.error(res.msg);
        }
      });
    },
    beforeDestroy() {
      this.editor.destroy(); // 组件销毁时销毁编辑器
      this.editor = null;
    },
    reForm() {
     return this.form  = {
        propertyDataList: [
          {
            //数据项
            dataKey: "",
            dateValue: "",
            dateUnit: "",
          },
        ],
        projectId: "", //项目ID
        propertyFundTotal: "", //产业基金数量
        propertyFundAmount: "", //产业基金金额
        enterpriseEnergizeText: "", //富文本内容
        starEnterpriseImgList: [], //明星企业合集
        projectHonourImgList: [], //园区荣誉图片集合
        starEnterpriseShowImgList: [], //明星企业展示图集合
        highQualityMerchantImgList: [], //优质商户图片集合
        enterpriseNeedsQrCodeImgList: [], //企业需求二维码图片集合
        projectSupportingImgList: [], //园区配套图片集合
      }
    },
    reImgArr() {
      return this.imgArr = {
            starEnterpriseImgList: [], // 明星企业合集
            projectHonourImgList: [], // 园区荣誉图片集合
            starEnterpriseShowImgList: [], // 明星企业展示图集合
            highQualityMerchantImgList: [], // 优质商户图片集合
            enterpriseNeedsQrCodeImgList: [], // 企业需求二维码图片集合
            projectSupportingImgList: [], // 园区配套图片集合
          }
    },
    populateFormWithImages() {
      for (const key in this.imgArr) {
      // 检查 imgArr 是否存在对应 form 的字段，并且是数组
      if (this.form.hasOwnProperty(key) && Array.isArray(this.imgArr[key])) {
        if (this.imgArr[key].length > 0) {
          // 将数组中每个对象的 response.redirect_uri 提取出来
          this.form[key] = this.imgArr[key].map(item => item.response?.redirect_uri || '');
        } else {
          // 如果数组为空则直接赋值空数组
          this.form[key] = [];
        }
      }
    }
    }
  },
};
</script>
  
  <style scoped>
div {
  box-sizing: border-box;
}
.title {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
}
.title1 {
  margin-top: 10px;
}
.titleTip {
  font-size: 12px;
  font-weight: 500;
  color: #aaa;
}
.dataItem {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  width: 0 !important;
}
.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.magin20 {
  margin-top: 20px;
}
.maginleft10 {
  margin-left: 10px;
}
</style>
  