import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"

// 运营数据菜单-项目列表
export function useProjectList(data) {
    return fetchApi(URL.USERROLE_PROJECTLIST, data, "POST", "body");
}

// 运营数据管理-保存
export function manageSave(data) {
    return fetchApi(URL.OPERATE_DATE_MANAGE_SAVE, data, "POST", "body");
}


// 运营数据管理-详情
export function manageDetail(data) {
    return fetchApi(URL.OPERATE_DATE_MANAGE_DETAIL, data);
}